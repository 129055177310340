import TomSelect from "tom-select";
import { Loader as GoogleMapsLoader } from "@googlemaps/js-api-loader";
import { closeSidebar } from "../../martide/sidebar";

interface WorkHistory {
  company: string | null;
  vessel_name: string | null;
  rank: string | null;
  start_at: string | null;
  end_at: string | null;
  is_current: boolean;
}

interface Candidate {
  id: string | null;
  application_date: string | null;
  avatar: string | null;
  email: string | null;
  ex_crew: boolean;
  full_name: string | null;
  home_phone: string | null;
  initials: string | null;
  manning_agent_initials: string | null;
  manning_agent_name: string | null;
  manning_agent_logo: string | null;
  mobile_phone: string | null;
  rank: string | null;
  readiness_date: string | null;
  vessel_id: string | null;
  work_histories: WorkHistory[];
  profile_path: string;
}

interface Location {
  id: string;
  candidates: Candidate[];
  lat: number;
  lon: number;
  heading: number;
  vessel_id: string;
  vessel_initials: string;
  vessel_name: string;
  vessel_photo: string | null;
  vessel_type: string | null;
}

interface LocationOption {
  title: string;
  id: string;
}

// Renders an avatar image or initials
function avatar(
  avatar: string | null,
  alt: string | null,
  initials: string | null,
) {
  if (avatar) {
    return `
      <img
        src=${avatar}
        class="w-10 h-10 rounded-full object-cover"
        alt=${alt || ""}
        loading="lazy"
      />
    `;
  } else {
    return `
      <div class="relative inline-flex h-10 w-10 items-center justify-center overflow-hidden rounded-full bg-gray-100 dark:bg-gray-600">
        <span class="font-medium text-gray-600 dark:text-gray-300">${initials || ""}</span>
      </div>
    `;
  }
}

function updateURL(locationId: string | null) {
  const url: URL = new URL(window.location.href);

  if (locationId) {
    url.searchParams.set("id", locationId);
  } else {
    url.searchParams.delete("id");
  }

  window.history.pushState({}, "", url.toString());
}

const VesselLocation = {
  mounted(this: any) {
    // Clear selection on escape key press
    document.addEventListener("keydown", (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        this.clearSelection();
      }
    });

    closeSidebar();

    // Initialize Google Maps loader with API key
    const loader = new GoogleMapsLoader({
      apiKey: this.el.dataset.apikey,
      version: "weekly",
    });

    // State management for map, markers, selection and info panels
    this.map = null;
    this.markers = {};
    this.select = null;
    this.selectedVessel = null;
    this.selectedAddress = null;
    this.selectedCandidate = null;
    this.infoHeaderDiv = document.createElement("div");
    this.infoBodyDiv = document.createElement("div");

    this.infoHeaderDiv.classList.add("mt-10");

    this.createSearchControl = () => {
      // Creates a custom search control with TomSelect integration
      // This allows searching for vessels by name/IMO
      const locationOptions = JSON.parse(this.el.dataset.locationOptions);

      // Create the control div
      const controlDiv = document.createElement("div");
      controlDiv.classList.add(
        "m-2",
        "p-5",
        "bg-white",
        "rounded-md",
        "shadow-md",
      );

      // Create wrapper div for search and close button
      const searchWrapper = document.createElement("div");
      searchWrapper.classList.add("flex", "items-center", "gap-2", "mb-2");

      // Create the search div
      const searchDiv = document.createElement("div");
      searchDiv.classList.add("bg-white", "flex-1");

      // Create close button
      const closeButton = document.createElement("button");
      closeButton.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      `;
      closeButton.classList.add(
        "p-1.5",
        "bg-white",
        "rounded-full",
        "shadow-md",
        "hover:bg-gray-100",
        "focus:outline-none",
        "flex-shrink-0",
        "hidden",
      );
      closeButton.addEventListener("click", () => {
        this.clearSelection();
      });

      // Add a select element to the custom control
      const selectElement = document.createElement("select");
      selectElement.classList.add("w-72", "font-sans");

      searchDiv.appendChild(selectElement);

      // Add elements to wrapper
      searchWrapper.appendChild(searchDiv);
      searchWrapper.appendChild(closeButton);

      // Add wrapper and info divs to control div
      controlDiv.appendChild(searchWrapper);
      controlDiv.appendChild(this.infoHeaderDiv);
      controlDiv.appendChild(this.infoBodyDiv);

      // Store close button reference for later use
      this.closeButton = closeButton;

      // Add the custom control to the map
      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(controlDiv);

      const options = locationOptions.map(({ id, title }: LocationOption) => ({
        id: id,
        title: title,
      }));

      // Initialize TomSelect with vessel search options
      this.select = new TomSelect(selectElement, {
        create: false,
        hideSelected: true,
        maxItems: 1,
        options: options,
        placeholder: "Search",
        valueField: "id",
        labelField: "title",
        searchField: ["title"],
        sortField: "title",
      });

      // Handle vessel selection change
      this.select.on("change", (value: any) => {
        this.select.blur();
        this.pushEvent("select_changed", { id: value });
      });
    };

    this.updateSearchControl = () => {
      // Updates the search control and info panels based on selection state
      // Handles three states:
      // 1. Selected candidate details
      // 2. Selected vessel details
      // 3. No selection

      const locationOptions = JSON.parse(this.el.dataset.locationOptions);

      // Show/hide close button based on selection state
      if (this.closeButton) {
        if (this.selectedLocation || this.selectedCandidate) {
          this.closeButton.classList.remove("hidden");
        } else {
          this.closeButton.classList.add("hidden");
        }
      }

      if (this.select) {
        this.select.clearOptions();
        this.select.addOptions(locationOptions);
      }

      if (this.selectedCandidate) {
        let exCrew = "";

        if (this.selectedCandidate.ex_crew) {
          exCrew = `
            <div class="flex justify-center">
              <span class="px-4 py-2 text-sm font-medium text-green-700 bg-green-100 rounded-full">
                Ex-Crew
              </span>
            </div>
          `;
        }

        this.infoHeaderDiv.innerHTML = `
          <div class="w-full max-w-sm mx-auto bg-white rounded-lg shadow-md p-6 space-y-4">
            <div id="back-to-vessel-btn" class="text-blue-600 text-sm font-semibold cursor-pointer">
              &larr; Back to Vessel
            </div>

            <div class="flex flex-col items-center text-center space-y-2">
              ${avatar(this.selectedCandidate.avatar, this.selectedCandidate.full_name, this.selectedCandidate.initials)}
              <a href="${this.selectedCandidate.profile_path}" target="_blank" class="text-lg font-bold text-gray-800 hover:text-blue-600">${this.selectedCandidate.full_name || ""}</a>
              <p class="text-sm font-semibold text-gray-500 uppercase">${this.selectedCandidate.rank || ""}</p>
            </div>

            <div class="text-sm space-y-1 text-gray-700">
              <p>Email: <span class="font-medium">${this.selectedCandidate.email || ""}</span></p>
              <p>Mobile: <span class="font-medium">${this.selectedCandidate.mobile_phone || ""}</span></p>
              <p>Home: <span class="font-medium">${this.selectedCandidate.home_phone || ""}</span></p>
              <p>Readiness: <span class="font-medium">${this.selectedCandidate.readiness_date || ""}</span></p>
              <p>Application: <span class="font-medium">${this.selectedCandidate.application_date || ""}</span></p>
            </div>

            ${exCrew}

          </div>
        `;

        const backBtn = document.getElementById("back-to-vessel-btn");

        if (backBtn) {
          backBtn.addEventListener("click", () => {
            this.selectedCandidate = null;
            this.updateSearchControl();
          });
        }

        const workHistories = this.selectedCandidate.work_histories
          .map((workHistory: WorkHistory) => {
            return `
            <div class="text-sm text-gray-700">
              <p class="font-semibold">${workHistory.company || ""}</p>
              <p class="text-gray-500">Rank: ${workHistory.rank || ""}</p>
              <p class="text-gray-500">Period: ${workHistory.start_at || ""} - ${workHistory.end_at || ""}</p>
            </div>
          `;
          })
          .join("");

        this.infoBodyDiv.innerHTML = `
          <div class="w-full max-w-sm mx-auto bg-white rounded-lg shadow-md p-6 space-y-4">
            <!-- Manning agent and Positions -->
            <div class="space-y-4">
              <div>
                <p class="text-sm font-bold text-gray-800">Manning Agent</p>
                <p class="flex items-center space-x-2 text-sm text-gray-700">
                  ${avatar(this.selectedCandidate.manning_agent_logo, this.selectedCandidate.manning_agent_name, this.selectedCandidate.manning_agent_initials)}
                  <span>${this.selectedCandidate.manning_agent_name || ""}</span>
                </p>
              </div>

              <div>
                <p class="text-sm font-bold text-gray-800 mb-4">Last 5 Positions</p>
                <div class="flex flex-col space-y-4">
                  ${workHistories}
                </div>
              </div>
            </div>
          </div>
      `;
      } else if (this.selectedLocation) {
        // Reverse geocode vessel location to get address
        loader.importLibrary("geocoding").then(({ Geocoder }) => {
          const latlng = {
            lat: this.selectedLocation?.lat ?? 0,
            lng: this.selectedLocation?.lon ?? 0,
          };
          const geocoder = new Geocoder();

          geocoder
            .geocode({ location: latlng })
            .then((response) => {
              const { results } = response;
              if (Array.isArray(results)) {
                const result = results.at(-2) || results.at(-1);
                if (result) {
                  this.selectedAddress = result.formatted_address;
                }
              }
            })
            .catch((error) => {
              console.error("Geocoding failed:", error);
            });
        });

        let vesselPhoto = null;

        if (this.selectedLocation.vessel_photo) {
          vesselPhoto = `
            <img
              src=${this.selectedLocation.vessel_photo}
              class="w-96 h-60 rounded-lg my-5"
              alt=${this.selectedLocation.vessel_name}
              loading="lazy"
            />
          `;
        } else {
          vesselPhoto = `
            <div class="relative inline-flex h-60 w-96 items-center justify-center overflow-hidden rounded-lg bg-gray-100 dark:bg-gray-600 my-5">
              <span class="font-medium text-gray-600 dark:text-gray-300">${this.selectedLocation.vessel_initials}</span>
              </div>
          `;
        }

        this.infoHeaderDiv.innerHTML = `
          <div>
            <h3 class="m-0 text-xl">${this.selectedLocation.vessel_name}</h3>
            <p class="m-0 text-sm text-gray-500">${this.selectedLocation.vessel_type}</p>
            ${vesselPhoto}
            <div class="flex items-center">
              <div class="bg-gray-200 rounded-full w-10 h-10 flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                </svg>
              </div>
              <div class="ml-4 text-gray-700">
                <h2 class="text-base font-medium">${this.selectedAddress || ""}</h2>
                <p class="text-sm text-gray-500">${this.selectedLocation.lat}, ${this.selectedLocation.lon}</p>
              </div>
            </div>
            <div class="flex items-center mt-5">
              <div class="bg-gray-200 rounded-full w-10 h-10 flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                </svg>
              </div>
              <div class="ml-4 text-gray-700">
                <p class="text-base font-medium">${this.selectedLocation.candidates.length} seafarers on board</p>
              </div>
            </div>
          </div>
        `;

        this.infoBodyDiv.innerHTML = `
          <div class="border-t border-gray-300 mt-5 overflow-y-auto h-96">
            ${this.selectedLocation.candidates
              .map((candidate: Candidate) => {
                return `
                  <div class="flex items-center space-x-4 p-4 candidate-list-item" data-candidate-id="${candidate.id}">
                    ${avatar(candidate.avatar, candidate.full_name, candidate.initials)}
                    <div>
                      <p class="font-semibold text-gray-500 uppercase">${candidate.rank || ""}</p>
                      <p class="font-bold text-gray-800">${candidate.full_name || ""}</p>
                    </div>
                  </div>
                `;
              })
              .join("")}
          </div>
        `;

        // Then attach event listeners to elements within infoBodyDiv
        const candidateElements = this.infoBodyDiv.querySelectorAll(
          ".candidate-list-item",
        );
        candidateElements.forEach((el: any) => {
          el.addEventListener("click", () => {
            const candidateId = el.dataset.candidateId;

            const candidate = this.selectedLocation.candidates.find(
              (candidate: Candidate) => candidate.id === candidateId,
            );

            if (candidate) {
              this.selectedCandidate = candidate;
            } else {
              this.selectedCandidate = null;
            }

            this.updateSearchControl();
          });
        });
      } else {
        this.infoHeaderDiv.innerHTML = null;
        this.infoBodyDiv.innerHTML = null;
      }
    };

    this.updateMarkers = () => {
      // Updates vessel markers on the map
      // Creates or updates markers for each vessel location
      const locations = JSON.parse(this.el.dataset.locations);

      loader.importLibrary("marker").then(({ AdvancedMarkerElement }) => {
        locations.forEach(
          ({ id, lat, lon, heading, vessel_name }: Location) => {
            const content = new DOMParser().parseFromString(
              `
              <div class="relative group">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" stroke="#000" stroke-linecap="round"
                stroke-linejoin="round" fill="#fff" fill-rule="evenodd">
                  <path
                    style="fill: ${this.selectedLocation?.id == id ? "#22c55e" : "#3b82f6"}; stroke: ${this.selectedLocation?.id == id ? "#22c55e" : "#3b82f6"};"
                    d="M20 20L10 0L0 20l10-5z"
                    transform="rotate(${heading} 10 10)" />
                </svg>
                <div class="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 hidden group-hover:block bg-white p-2 rounded shadow-lg">
                  <p class="whitespace-nowrap">${vessel_name}</p>
                </div>
              </div>
            `,
              "text/html",
            ).documentElement;

            const marker = new AdvancedMarkerElement({
              map: this.map,
              position: { lat: lat, lng: lon },
              title: vessel_name,
              content: content,
              gmpClickable: true,
            });

            this.markers[id] = marker;

            // Handle marker click to select vessel
            marker.addListener(
              "click",
              ({ domEvent }: google.maps.MapMouseEvent) => {
                this.selectedLocation = null;
                this.selectedAddress = null;
                this.selectedCandidate = null;
                this.pushEvent("select_changed", { id: id });
              },
            );
          },
        );
      });
    };

    this.handleLocationSelect = (location: Location) => {
      if (location.id) {
        this.selectedLocation = location;
        updateURL(location.id);
      } else {
        this.selectedLocation = null;
        updateURL(null);
      }

      this.markers = {};
      this.updated();

      if (this.selectedLocation) {
        this.select.addItem(this.selectedLocation.vessel_id, true);
        this.map.panTo({
          lat: this.selectedLocation.lat,
          lng: this.selectedLocation.lon,
        });
        this.map.setZoom(8);
      } else {
        this.map.panTo({ lat: 0, lng: 0 });
        this.map.setZoom(4);
      }
    };

    this.handleEvent("select_location", this.handleLocationSelect);

    this.handleEvent(
      "select_candidate",
      ({
        candidate,
        location,
      }: {
        candidate: Candidate;
        location: Location;
      }) => {
        this.selectedCandidate = candidate;
        this.handleLocationSelect(location);
        this.updateSearchControl();
      },
    );

    this.handleEvent(
      "location_options",
      ({ options }: { options: LocationOption[] }) => {
        this.updateSearchControl();
      },
    );

    loader.importLibrary("maps").then(({ Map }) => {
      this.map = new Map(this.el, {
        mapId: "FLEETMAP",
        cameraControl: false,
        center: { lat: 0, lng: 0 },
        zoom: 4,
        maxZoom: 14,
        minZoom: 4,
        gestureHandling: "greedy",
        disableDefaultUI: true,
        disableDoubleClickZoom: true,
        fullscreenControl: false,
        headingInteractionEnabled: false,
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: true,
      });

      this.createSearchControl();
      this.pushEvent("map_loaded", {});
    });

    this.updateMarkers();
  },

  updated(this: any) {
    this.updateMarkers();
    this.updateSearchControl();
  },

  clearSelection(this: any) {
    this.selectedLocation = null;
    this.selectedAddress = null;
    this.selectedCandidate = null;
    this.select.clear();
    updateURL(null);
    this.pushEvent("select_changed", { id: null });
  },
};

export default VesselLocation;
